$gray-100: #abc2be;
$purple-100: #9520d6;
$purple-500: #5f1171;
$blue-100: #8aa6e7;

$param-bg: $gray-100;
$special-text: $blue-100;
$text-animate: $purple-500;

$breakpoint1: 800px;

div {
  display: flex;
}

button {
  background-color: $param-bg;
  border-radius: 3px;
  border: 2px solid $param-bg;
  color: white;
  cursor: pointer;
}

a {
  color: $text-animate;
}

.playButtons {
  color: $text-animate !important;
  justify-content: space-evenly;
  min-width: 160px;
  button {
    color: inherit;
    background-color: $param-bg;
    border-color: $param-bg;
    margin-right: 0px !important;
  }
  button:disabled {
    color: gray;
    cursor: default;
  }
}

.App {
  background-color: white;
  justify-content: center;
  width: 100%;

  &.fullScreen {
    background-color: black;
  }
}

.section-title {
  cursor: pointer;
  &.fullScreen {
    color: white;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  //font-size: calc(10px + 2vmin);
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

.App-small {
  font-size: small;
  color: black;
  display: block;

  &.fullScreen {
    color: white;
  }
}

.controlsTitle {
  @media screen and (min-width: $breakpoint1) {
    margin-top: 11px;
  }
}

.controlsHelp {
  flex-direction: column;
  margin: 10px;
  max-width: 250px;
  div {
    margin-bottom: 15px;
    span {
      font-weight: bold;
    }
  }
}

.mainPanel {
  flex-direction: column;

  button {
    margin-right: 20px;
    height: fit-content;
  }
}

.controlBar {
  height: 30px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $breakpoint1) {
    height: initial;
    flex-direction: column;
  }
}

.appTitle {
  color: $param-bg;
  margin-right: 10px;
  font-size: large;
  font-weight: bold;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wheelWell {
  @media screen and (max-width: $breakpoint1) {
    flex-direction: column-reverse;
  }
}

.controlsToggle {
  @media screen and (max-width: $breakpoint1) {
    display: none;
  }
}

.playControls {
  margin: 5px;
  @media screen and (max-width: $breakpoint1) {
    flex-direction: column;
    align-items: center;
  }
  div {
    align-items: baseline;
    color: $param-bg;
  }
  input[type=text], .frameNum {
    color: $text-animate;
    border: 1px solid $param-bg;
    border-radius: 7px;
    padding-right: 5px;
  }
  .frameNum {
    width: 50px;
    margin-right: 2px;
    margin-left: 2px;
    text-align: right;
    display: inline;
    padding: 2px;
    padding-top: 1px;
    padding-right: 5px;
    font-size: smaller;
  }
  .tempo {
    margin-left: 4px;
  }
}

.animationControls {
  @media screen and (max-width: $breakpoint1) {
    margin-top: 8px;
  }
  button {
    margin-left: 8px;
    margin-right: 3px;
    &.selected {
      color: purple
    }
  }
}

.wheelControls {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.viewOptions {
  justify-content: space-evenly;
  align-items: center;
  @media screen and (max-width: $breakpoint1) {
    flex-direction: column;
  }
}
.stage {
  margin: 10px auto;

  &.clickable {
    cursor: pointer;
  }
}

.vizSetting {
  padding: 5px;
  background-color: $param-bg;
  margin: 2px;
  flex-direction: column;
  border-radius: 2px;
  font-size: 14px;
  color: white;

  input {
    font-family: sans-serif;
    /*margin-right: 4px !important;*/
    /*margin-left: 4px !important;*/
  }

  > label {
    &.enabled {
      color: $text-animate;
    ;
    }
  }
}
.vizParamElement {
  padding: 5px;
  background-color: $param-bg;
  justify-content: space-between;
  border-radius: 2px;
  font-size: 14px;
  color: white;

  input {
    font-family: sans-serif;
    /*margin-right: 4px !important;*/
    /*margin-left: 4px !important;*/
  }

  > label {
    &.enabled {
      color: $text-animate;
    }
  }
}

.mint-settings {
  flex-direction: column;
  margin: 7px;
  width: 100%;
  background-color: $param-bg;

  .setting {
    padding: 5px;
    background-color: $param-bg;
    margin: 2px 9px;
    border-radius: 2px;
    font-size: 14px;
    color: white;
    justify-content: space-between;
    align-items: center;

    input[type='text'] {
      font-family: sans-serif;
      width: 150px;
      text-align: left;
    }
    .number {
      width: 50px !important;
      text-align: right !important;
    }
  }
}

.viz-settings {
  flex-direction: column;
  //flex-flow: wrap;
  margin: 7px;
  width: 100%;

  .group {
    background-color: $param-bg;
    flex-direction: column;
    width: 100%;

    .name {
      padding: 4px;
      font-size: smaller;
      border-bottom: 1px dotted black;
      background-color: #a4c3be;
      cursor: pointer;

      &.enabled {
        color: $text-animate;
      }
    }
    .params {
      flex-direction: column;
    }
  }
}

.viz-ui {
  flex: 2;
  flex-direction: column;
  align-items: center;
  font-size: large;
  max-width: 350px;
  margin-right: 11px;

  .layout {
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;

    div {
      padding-right: 10px;
    }

    .disclosure {
      margin-right: 4px;
    }

    .labeledSelect label,
    .labeledCheckbox label {
      vertical-align: baseline;
      margin-right: 2px;
      color: black;
    }
  }
  .formula {
    color: white;
    font-size: x-small;
    margin-left: 19px;
    margin-right: 19px;
    max-width: 95%;
  }
}

.labeledCheckbox {
  margin-left: 4px;
}
.labeledSelect {
  margin-left: -3px;
  label {
    margin-right: 2px;
  }
}

input[type='text'] {
  width: 50px;
  height: fit-content;
  margin-right: 2px;
  margin-left: 2px;
  text-align: right;
  padding: 2px;
}

label {
  font-size: 14px;
  vertical-align: middle;
  padding-left: 2px;
}

.vizParamSettings {
  padding-bottom: 1px;
  border-radius: 3px;
}

.vizValue {
  background-color: white;
  width: 50px;
  margin: 2px;
  height: fit-content;
  text-align: right;
  display: inline;
  border: 1px dashed $param-bg;
  color: black;
  padding: 2px;
  font-family: sans-serif;
  overflow: hidden;
}

legend {
  text-align: left;
}

.clickable {
  cursor: pointer;
}

.param {
}

.wheelActions {
  justify-content: space-evenly;
  align-items: baseline;
  font-size: 14px;
  margin-top: 7px;

  div {
    align-items: baseline;
  }

  button {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.copyConfirmation {
  font-style: italic;
  padding-left: 7px;
}

.footer {
  margin-top: 20px;
  color: black;
  font-size: x-small;
}
